import React from "react";
import { Link } from "react-router-dom";
import { useGetEightNews } from "../../hooks/query";
import { GrFormNextLink } from "react-icons/gr";
import AllNewsItem from "./AllNewsItem";
import { Loader } from "@mantine/core";
import { useTranslation } from "react-i18next";

const AllNews = () => {
  const useGetEightNewsList = useGetEightNews();
  const { t } = useTranslation();
  if (useGetEightNewsList.isLoading) {
    return (
      <div className="flex justify-center left-[350px] h-[600px]">
        <Loader color="#FF7D00" size="xl" variant="bars" />
      </div>
    );
  }
  return (
    <div>
      <div className="text-white overflow-y-scroll h-[500px] scrollbar-thumb-[#FFECD1] scrollbar-track-[#15616D] scrollbar-thin">
        <div className="p-4">
          {useGetEightNewsList.data?.map((item) => (
            <AllNewsItem item={item} key={item.id} />
          ))}
        </div>
      </div>
      <Link to="/all-news">
        <span className="flex gap-2 px-1 py-2 hover:bg-[#EEF2FF] duration-200 rounded-r-full items-center w-[200px] m-4 ">
          <p>{t("Ko'proq yangilik")} </p>
          <GrFormNextLink size={22} />
        </span>
      </Link>
    </div>
  );
};

export default AllNews;
