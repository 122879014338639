import React from "react";
import { useGetNavbar } from "../../../hooks/query";
import { Link } from "react-router-dom";

const NavbarItem = ({ setOpened }) => {
  const useGetNavbarList = useGetNavbar();
  console.log(useGetNavbarList);

  return (
    <div className="grid grid-cols-5 gap-4 mx-[20px]">
      {useGetNavbarList.data?.map((item) => (
        <div>
          <h5 className="font-bold mb-[20px] text-xl">{item.name}</h5>
          {item.children.map((subItem) => (
            <Link
              onClick={() => setOpened(false)}
              to={`/ndetail/${subItem.slug}`}
              className="hover:text-[#FF7D00] duration-300"
            >
              <li>{subItem.name}</li>
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};

export default NavbarItem;
