import React from "react";
import { useGetPopularNews } from "../../hooks/query/useGetPopularNews";
import NewsHeroItem from "./NewsHeroItem";
import { Loader } from "@mantine/core";
import { useTranslation } from "react-i18next";

const NewsHero = () => {
  const { t } = useTranslation();
  const useGetPopularNewsList = useGetPopularNews();
  if (useGetPopularNewsList.isLoading) {
    return (
      <div className="flex justify-center h-[600px]">
        <Loader color="#FF7D00" size="xl" variant="bars" />
      </div>
    );
  }
  return (
    <section>
      <h3 className="text-center py-[30px] font-semibold text-2xl">
        {t("Dolzarb xabarlar")}
      </h3>
      <div className="lg:grid md:grid grid-cols-2 gap-8">
        {useGetPopularNewsList.data?.map((item) => {
          return <NewsHeroItem item={item} key={item.id} />;
        })}
      </div>
    </section>
  );
};

export default NewsHero;
