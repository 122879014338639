import React from "react";
import gerb from "../../../assets/images/gerb.png";
import karta from "../../../assets/images/karta.png";
import ScrollToTop from "./scrollToTop/ScrollToTop";
import { FiInstagram, FiYoutube } from "react-icons/fi";
import { FaTelegramPlane } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:h-[400px] md:h-[400px] bg-[#001524] text-white">
      <div className="max-w-[1280px] mx-auto lg:flex md:flex justify-between pt-4 lg:px-0 md:px-3 px-4">
        <div className="lg:w-[650px] md:w-[600px] flex flex-col gap-4 lg:text-[17px] md:text-[16px]">
          <div className="flex gap-2 items-center pt-[6px]">
            <img src={gerb} alt="gerb" width="50px" height="50px" />
            <h2 className="text-xl font-semibold text-white">
              {t("Toshkent tumani hokimligi")}
            </h2>
          </div>
          <p>
            {t(
              "Sayt Toshkent viloyati hokimligi huzuridagi, Axborot kommunikatsiya texnologiyalarini rivojlantirish markazi mutaxassislari tomonidan yaratilgan © 2023"
            )}
          </p>
          <p>
            {t(
              "Ushbu sayt materiallaridan foydalanganda www.toshkenttumani.uz saytiga havola qilish majburiydir"
            )}
          </p>
          <a className="hover:text-[#FF7D00] duration-300" href="/saytxaritasi">
            {t("Sayt xaritasi")}
          </a>
          <div className="flex gap-6 mt-8">
            <a
              className="hover:text-[#FF7D00] duration-300"
              href="https://www.instagram.com/toshkent_tumani_hokimligi/"
              target="_blank"
            >
              <FiInstagram size={26} />
            </a>
            <a
              className="hover:text-[#FF7D00] duration-300"
              href="https://www.youtube.com/channel/UC-HC-DeTCwm5rNV7-HZRzvw"
              target="_blank"
            >
              <FiYoutube size={29} />
            </a>
            <a
              className="hover:text-[#FF7D00] duration-300"
              href="https://t.me/toshkenttumanihokimligi"
              target="_blank"
            >
              <FaTelegramPlane size={28} />
            </a>
            <a
              className="hover:text-[#FF7D00] duration-300"
              href="https://www.facebook.com/toshkenttumanihokimligi"
              target="_blank"
            >
              <BsFacebook size={26} />
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-[10px]">
          <p>{t("Bizning manzil:")}</p>
          <p>{t("Toshkent tumani, Keles yo'li, 8-uy")}</p>
          <a
            href="https://www.google.com/maps/place/Hokimyat/@41.400563,69.2024146,16.92z/data=!4m12!1m5!3m4!2zNDHCsDI2JzU3LjAiTiA2OcKwMTQnMTEuMSJF!8m2!3d41.4491667!4d69.2364167!3m5!1s0x38ae8dcbc4f5da83:0xf3ec80b351912071!8m2!3d41.3998491!4d69.2053161!16s%2Fg%2F11gr4j3dh2"
            target="_blank"
            className="overflow-hidden bg-no-repeat bg-cover"
          >
            <img
              src={karta}
              alt="harita"
              width="400px"
              height="400px"
              className="hover:scale-125 object-cover transition duration-700 ease-in-out"
            />
          </a>
          <p>
            {t("Tel: +998 71 203 55 22")} <br />
          </p>
          <p>{t("Murojaat qilish vaqti: 9:00 / 18:00")}</p>
        </div>
      </div>

      <ScrollToTop />
    </div>
  );
};

export default Footer;
