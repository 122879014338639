import Slider from "react-slick";
import { useGetPhotoCarusel } from "../../hooks/query";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import React, { useTransition } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GoToPhoto = () => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    className: "center",
    slidesToShow: 4,
    infinite: true,
    slidesToScroll: 1,
    autoplay: false,
    cssEase: "linear",
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <NextBtn />,
    prevArrow: <PrevBtn />,
  };

  const useGetPhotoCarouselList = useGetPhotoCarusel();

  function NextBtn(props) {
    const { onClick } = props;
    return (
      <button
        className="font-bold absolute top-[44%] -right-9 text-gray-400  z-50 -translate-y-1/2 lg:block md:hidden hidden"
        onClick={onClick}
      >
        <IoIosArrowForward size={40} />
      </button>
    );
  }

  function PrevBtn(props) {
    const { onClick } = props;
    return (
      <button
        className="font-bold text-gray-400 z-50 absolute  top-[44%] -left-9 -translate-y-1/2 lg:block md:hidden hidden"
        onClick={onClick}
      >
        <IoIosArrowBack size={40} />
      </button>
    );
  }

  return (
    <div className="h-[350px] mt-[100px]">
      <h3 className="text-center font-semibold lg:text-2xl md:text-xl mb-[30px]">
        {t("Tuman hayotidan fotolavhalar")}
      </h3>
      <Slider {...settings}>
        {useGetPhotoCarouselList.data?.map((item) => {
          return (
            <Link key={item.id} to="/photogalery">
              <div>
                <div className="h-[210px] overflow-hidden bg-no-repeat bg-cover">
                  <img
                    className="hover:scale-125 object-cover transition duration-700 ease-in-out"
                    src={item.post_img}
                    alt="rasimlar"
                    height="100%"
                    width="100%"
                  />
                </div>
              </div>
            </Link>
          );
        })}
      </Slider>
    </div>
  );
};

export default GoToPhoto;
