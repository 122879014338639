import React from "react";
import karta2 from "../../assets/images/karta2.jpg";
import { useTranslation } from "react-i18next";
import round1 from "../../assets/images/round1.png";
import round2 from "../../assets/images/round2.png";
import round3 from "../../assets/images/round3.png";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:flex gap-[100px] relative">
      <img src={round2} alt="" className="absolute top-[250px] left-[420px]" />
      <img src={round3} alt="" className="absolute right-[530px]" />
      <img src={round1} alt="" className="absolute right-[0px] top-[208px]" />
      <div className="flex flex-col mt-[20px] lg:mt-0 overflow-hidden bg-no-repeat bg-cover ">
        <img
          src={karta2}
          alt="region info"
          height={550}
          width={500}
          className="rounded-lg relative z-10 hover:scale-125 object-cover transition duration-700 ease-in-out"
        />
      </div>
      <div className="flex flex-col gap-2 text-black text-[16px] relative z-10">
        <h4 className="flex justify-center w-[495px] font-semibold text-[40px]">
          {t("Toshkent tumani haqida")}
        </h4>
        <div className="w-[618px]">
          <p>
            {t(
              "Toshkent tumani — Toshkent viloyatidagi tuman. 1975-yil 25 noyabrda tashkil qilingan. Shimolidan Qozogʻiston, sharqdan Qibray tumani, janubidan Toshkent shahri, gʻarbdan Zangiota tumani bilan chegaradosh. Maydoni 0,16 ming km². Tumanda 1 shahar (Keles), 9 qishloq fuqarolari yigʻini (Guliston, Koʻkterak, Koʻksaroy, Masalboy, Choshtepa, Chuvalachi, Yunusobod, Qizgʻaldoq, Hasanboy) bor. Markazi – Keles shahri."
            )}
          </p>
          <p>
            {t(
              "Tuman hududi Chirchiq va Keles daryolari vodiysida joylashgan. Relyefi, asosan, pastbaland tekislikdan iborat. Iqlimi kontinental. Yillik oʻrtacha temperatura 13,3°. Yanvarning oʻrtacha temperaturasi –0,9°, –1,1°, eng past temperatura –29°. Iyulning oʻrtacha temperaturasi 26,7°, eng yuqori temperatura 42°. Yillik yogʻin 370–390 mm, asosan, qishning 2-yarmi va bahorda yogʻadi. Foydali qazilmalardan oʻtga chidamli gil, shagʻal, qum va issiq mineral suv buloqlari bor. Ekinlarni sugʻorishda Keles daryosi va Zaxariq hamda Boʻzsuv kanallaridan foydalaniladi."
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegionInfo;
